import {
  GoogleMap,
  Marker,
  InfoWindow,
  DrawingManager,
} from "@react-google-maps/api";
import axios from "axios";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AddButton } from "../Dashboard/Dashboard.styled";
import AddTree from "./AddTree";

export const CustomMarker2 = () => {
  const center = useMemo(() => ({ lat: 22.3089629, lng: 73.185845 }), []);
  //const center = useMemo(() => ({ lat: 22.3132992, lng: 73.1395705 }), []);

  const [data, setData] = useState([]);
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [treeData, setTreeData] = useState({
    Location: `${center.lat},${center.lng}`,
  });
  const [file, setFile] = useState();

  const getTreeData = () => {
    axios
      .post("https://plantationapi.encoraa.com/api/v1/lov/query", {
        query:
          "SELECT DISTINCT location,GROUP_CONCAT(DISTINCT NAME) fullname,GROUP_CONCAT(DISTINCT address) address,GROUP_CONCAT(DISTINCT pincode) pincode,GROUP_CONCAT(DISTINCT NameOfTree) tree,picture,SUBSTRING_INDEX(SUBSTRING_INDEX(location, ',', 1), ',', -1) AS lat,  SUBSTRING_INDEX(SUBSTRING_INDEX(location, ',', 2), ',', -1) AS lng,createddatetime, CASE WHEN DATE(createddatetime)< DATE(NOW()) THEN -1 ELSE 1 END isNew FROM plantationdetails WHERE location<>'' GROUP BY location ORDER BY createddatetime;",
      })
      .then((res) => setData(res.data));
  };

  useEffect(() => {
    getTreeData();
    console.log(window.location.protocol + "/" + window.location.host + "/");
    console.log(window.location.origin);
  }, []);

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    data?.forEach(({ lat, lng }) =>
      bounds.extend({ lat: Number(lat), lng: Number(lng) })
    );
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, markerData) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, ...markerData });
    setIsOpen(true);
  };

  const handleChange = (e) =>
    setTreeData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    var f = e.target.files[0];
    var reader = new FileReader();

    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        setTreeData((prev) => ({
          ...prev,
          Picture: binaryData,
          FileName: f.name,
        }));
      };
    })(f);

    reader.readAsDataURL(f);
  };

  const onSubmit = () => {
    let data = {
      ...treeData,
      Address: "",
      City: 1068,
      EMailID: "",
      Mobile: "",
      RefferalURL: "",
      RegisteredAs: 4,
      State: 12,
    };
    axios
      .post("https://plantationapi.encoraa.com/api/v1/plantation", data)
      .then((res) => {
        getTreeData();
        setOpenAddNew(false);
      });
  };

  return (
    <>
      {data.length > 0 ? (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
          onLoad={onMapLoad}
          onClick={() => setIsOpen(false)}
        >
          <DrawingManager />

          <AddButton
            className="btn btn-primary btn-lg rounded-0"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >{`Add New Tree`}</AddButton>

          {data.map((x, ind) => {
            const latlong = x.location.split(",");
            const c = { lat: Number(x.lat), lng: Number(x.lng) };

            return (
              <Marker
                position={c}
                icon={
                  x.isNew === 1
                    ? `${window.location.origin}/tree-new.svg`
                    : `${window.location.origin}/trees-svgrepo-com.svg`
                }
                //label={x.fullname.split(",")[0]}
                onClick={() => {
                  handleMarkerClick(ind, Number(x.lat), Number(x.lng), x);
                }}
                draggable
                onDragEnd={(mrk) =>
                  console.log(mrk.latLng.lat(), mrk.latLng.lng())
                }
              >
                {isOpen && infoWindowData?.id === ind && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item ">
                        <strong>{`Name: ${infoWindowData.fullname}`}</strong>
                      </li>

                      <li className="list-group-item">
                        <strong>{`Tree: ${infoWindowData.tree}`}</strong>
                      </li>
                      <li className="list-group-item">
                        <strong>{`Pincode: ${infoWindowData.pincode}`}</strong>
                      </li>
                      <li className="list-group-item">
                        <strong>{`Address: ${infoWindowData.address}`}</strong>
                      </li>
                    </ul>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
          onClick={() => setIsOpen(false)}
        >
          <Marker
            position={center}
            icon={`http://localhost:3001/trees-svgrepo-com.svg`}
          />
        </GoogleMap>
      )}

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title fs-5" id="exampleModalLabel">
                {`Add New Tree`}
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <AddTree
                treeData={treeData}
                onChange={handleChange}
                onFileChange={handleFileChange}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm rounded-0"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm rounded-0"
                onClick={() => onSubmit()}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
