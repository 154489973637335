import { GoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";

export const DefaultMapView = () => {
  // const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const [position, setPosition] = useState({
    latitude: 18.52043,
    longitude: 73.856743,
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log(position);
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  return (
    <GoogleMap
      mapContainerClassName="map-container"
      center={{ lat: position.latitude, lng: position.longitude }}
      zoom={100}
    ></GoogleMap>
  );
};
