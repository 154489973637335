import { useState } from "react";
import CustomMarker2 from "../Custom Marker2";
import CustomMapStyles from "../CustomMapStyles";
import CustomMarker1 from "../CustomMarker1";
import CustomMarker3 from "../CustomMarker3";
import DefaultMapView from "../DefaultMapView";
import DefaultMarker from "../DefaultMarker";
import DrawingTool from "../DrawingTool";
import DynamicMapView from "../DynamicMapView";
import { Geofence } from "../Geofence/Geofence";
import InformationWindow from "../InformationWindow";
import Sidebar from "../Sidebar";
import ZoomLevel from "../ZoomLevel";
import {
  DashboardContainer,
  MapSection,
  SidebarSection,
} from "./Dashboard.styled";
import { useEffect } from "react";
import axios from "axios";

export const Dashboard = () => {
  const [mapView, setMapView] = useState("customMarker2");
  const handleMapViewChnage = (value) => setMapView(value);

  // useEffect(() => {
  //   axios
  //     .post("https://plantationapi.encoraa.com/api/v1/lov/query", {
  //       query:
  //         "SELECT DISTINCT location,GROUP_CONCAT(NAME),GROUP_CONCAT(DISTINCT address),GROUP_CONCAT(DISTINCT pincode),GROUP_CONCAT(DISTINCT NameOfTree),picture FROM plantationdetails WHERE location<>'' GROUP BY location;",
  //     })
  //     .then((res) => console.log(res));
  // }, []);

  const renderMap = (feature) => {
    switch (feature) {
      case "defaultMap":
        return <DefaultMapView />;
      case "defaultMarker":
        return <DefaultMarker />;
      case "customMarker1":
        return <CustomMarker1 />;
      case "customMarker2":
        return <CustomMarker2 />;
      case "customMarker3":
        return <CustomMarker3 />;
      case "dynamicMapView":
        return <DynamicMapView />;
      case "infoWindow":
        return <InformationWindow />;
      case "zoomLevel":
        return <ZoomLevel />;
      case "drawingTool":
        return <DrawingTool />;
      case "geoFence":
        return <Geofence />;
      case "customMapStyles":
        return <CustomMapStyles />;
      default:
        return null;
    }
  };

  return (
    <DashboardContainer>
      {/* <SidebarSection>
        <Sidebar mapView={mapView} handleMapViewChnage={handleMapViewChnage} />
      </SidebarSection> */}
      <MapSection>{renderMap(mapView)}</MapSection>
    </DashboardContainer>
  );
};
