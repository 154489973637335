import { useState } from "react";

const AddTree = ({ treeData, onChange, onFileChange }) => {
  //const treeData = data;
  return (
    <form>
      <div className="row g-1 align-items-center">
        <div className="col-3">
          <label for="inputPassword6" className="col-form-label">
            Full Name
          </label>
        </div>
        <div className="col-9">
          <input
            type="text"
            name="Name"
            className="form-control form-control-sm"
            value={treeData.Name}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="col-3">
          <label for="inputPassword6" className="col-form-label">
            Location
          </label>
        </div>
        <div className="col-9">
          <input
            type="text"
            name="Location"
            className="form-control form-control-sm"
            onChange={(e) => onChange(e)}
            value={`${treeData.Location}`}
          />
        </div>
        <div className="col-3">
          <label for="inputPassword6" className="col-form-label">
            {`Pincode`}
          </label>
        </div>
        <div className="col-9">
          <input
            type="text"
            name="PinCode"
            className="form-control form-control-sm"
            onChange={(e) => onChange(e)}
            value={`${treeData.PinCode}`}
          />
        </div>
        <div className="col-3">
          <label for="inputPassword6" className="col-form-label">
            {`Tree Name`}
          </label>
        </div>
        <div className="col-9">
          <select
            className="form-select form-select-sm"
            name="NameOfTree"
            aria-label="Small select example"
            onChange={(e) => onChange(e)}
          >
            <option selected>Select Tree</option>
            <option value="Neem">Neem</option>
            <option value="Asopalav">Asopalav</option>
            <option value="Mango">Mango</option>
            <option value="Coconut">Coconut</option>
          </select>
        </div>
        <div className="col-3">
          <label for="inputPassword6" className="col-form-label">
            {`Photo`}
          </label>
        </div>
        <div className="col-9">
          <div class="input-group">
            <input
              type="file"
              class="form-control form-control-sm"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
              onChange={(e) => onFileChange(e)}
            />
          </div>
          <img src={treeData.Picture} alt="Tree" height={100} />
        </div>
      </div>
    </form>
  );
};

export default AddTree;
