import { GoogleMap, Marker } from "@react-google-maps/api";
import { useMemo } from "react";

export const CustomMarker3 = () => {
  const center = useMemo(() => ({ lat: 18.5128, lng: 73.8529 }), []);
  const svgMarker = {
    path: "M56.414,48.586L47.828,40H51c0.809,0,1.538-0.486,1.848-1.234s0.139-1.607-0.434-2.18L43.828,28H47 c0.809,0,1.538-0.486,1.848-1.234s0.139-1.607-0.434-2.18L39.828,16H43c0.809,0,1.538-0.486,1.848-1.234s0.139-1.607-0.434-2.18 l-12-12C32.023,0.195,31.512,0,31,0s-1.023,0.195-1.414,0.586l-12,12c-0.572,0.572-0.743,1.432-0.434,2.18S18.191,16,19,16h3.172 l-8.586,8.586c-0.572,0.572-0.743,1.432-0.434,2.18S14.191,28,15,28h3.172l-8.586,8.586c-0.572,0.572-0.743,1.432-0.434,2.18 S10.191,40,11,40h3.172l-8.586,8.586c-0.572,0.572-0.743,1.432-0.434,2.18S6.191,52,7,52h20v11c0,0.553,0.447,1,1,1h6 c0.553,0,1-0.447,1-1V52h20c0.809,0,1.538-0.486,1.848-1.234S56.986,49.158,56.414,48.586z M20,14c-0.547,0-0.789-0.211,0-1 c0.641-0.641,9-9,10-10s1-1,2,0c0,0,9.303,9.305,9.999,10s0.61,1,0.001,1C41.679,14,20.127,14,20,14z M16,26 c-0.547,0-0.766-0.234,0-1c0.444-0.443,5.951-5.951,9-9h12c3.001,3,8.384,8.385,9,9c0.859,0.859,0.469,1,0,1 C45.75,26,16.127,26,16,26z M12,38c-0.609,0-0.773-0.227,0-1c0.461-0.461,5.956-5.955,9-9h20c3.07,3.07,8.634,8.635,9,9 c0.648,0.648,0.656,1,0,1C49.479,38,12.135,38,12,38z M33,62h-4V52h4V62z M54,50c-1,0-45.297,0-46,0c-0.484,0-0.797-0.203,0-1 c0.478-0.477,5.96-5.961,9-9h28c3.065,3.066,8.614,8.615,9.002,9.002C54.695,49.695,54.578,50,54,50z",
    fillColor: "#21D179",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 0.7,
  };
  return (
    <GoogleMap mapContainerClassName="map-container" center={center} zoom={15}>
      <Marker position={center} icon={svgMarker} />
    </GoogleMap>
  );
};
