export const route = [
  { lat: 18.51454707519716, lng: 73.8537696352756 },
  { lat: 18.515016654278245, lng: 73.85378369073207 },
  { lat: 18.515585939287593, lng: 73.85378376556893 },
  { lat: 18.51566003155854, lng: 73.85378752539927 },
  { lat: 18.515876382632683, lng: 73.85380300709059 },
  { lat: 18.516282912533594, lng: 73.85383165784548 },
  { lat: 18.516391144126338, lng: 73.85383565996639 },
  { lat: 18.51660197254205, lng: 73.85389259774601 },
  { lat: 18.516938897877555, lng: 73.85390971192992 },
  { lat: 18.517353915981122, lng: 73.85392854448064 },
  { lat: 18.51760341543888, lng: 73.85394228850623 },
  { lat: 18.51786304875405, lng: 73.85398214019949 },
  { lat: 18.51817273627854, lng: 73.85403265833345 },
  { lat: 18.518270880576, lng: 73.85405864669173 },
  { lat: 18.518353587931887, lng: 73.85409041207521 },
  { lat: 18.518403182849962, lng: 73.85412192803113 },
  { lat: 18.518459453989585, lng: 73.85418160718172 },
  { lat: 18.518501538781123, lng: 73.85424803278084 },
  { lat: 18.51852725906521, lng: 73.85431706927781 },
  { lat: 18.518542836941876, lng: 73.8543767484284 },
  { lat: 18.51856153843445, lng: 73.85450561160832 },
  { lat: 18.51857155574905, lng: 73.8545932522409 },
  { lat: 18.518806491603204, lng: 73.85456520823463 },
  { lat: 18.519070988477534, lng: 73.85453401831698 },
  { lat: 18.519313701508562, lng: 73.85450619566012 },
  { lat: 18.519503891333375, lng: 73.85450386223147 },
  { lat: 18.519749341282697, lng: 73.85451882759307 },
  { lat: 18.520087754624466, lng: 73.8545380571112 },
  { lat: 18.520228517646167, lng: 73.85454696486514 },
  { lat: 18.520270800030406, lng: 73.85457982192557 },
];
