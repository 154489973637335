import styled from "styled-components";

export const DashboardContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const MapSection = styled.div`
  height: 100%;
  width: 100%;

  .map-container {
    height: 100%;
    width: 100%;
  }
`;

export const SidebarSection = styled.div`
  height: 100%;
  width: 20%;
  background: #14161a;
  color: #fff;
`;

export const AddButton = styled.button`
  position: absolute;
  right: 55px;
  top: 10px;
  border-radius: 0;
`;
